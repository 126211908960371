<template>
  <div id="root-account-creation">
    <div>
      <h2
        class="pb-3"
        align="center"
      >
        Créer un compte
      </h2>
      <div class="mb-4">
        <p>Crédits disponibles: {{ userData.Credit }}</p>
      </div>
      <AccountCreationForm
        v-if="userData.Credit >= 1"
      />
      <CreditsRenew
        v-else
      />
    </div>
  </div>
</template>

<script setup>
import AccountCreationForm from '@/views/AccountCreation/Form.vue'
import CreditsRenew from '@/components/credits/renew.vue'

import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const userData = computed(() => store.getters['user/userData'])
</script>

<style scoped>
#root-account-creation {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#root-account-creation > div {
  border: 1px solid var(--color-shadow);
  padding: 15px;
  border-radius: 10px;
}
</style>