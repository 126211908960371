<template>
  <div>
    <ul>
      <li
        v-for="(item, index) in chronology"
        :key="index"
        class="mb-2"
      >
        <p>{{ item.label }}</p>
        <p>{{ item.size }} événements</p>
      </li>
    </ul>
  </div>
</template>

<script setup>

import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const circles = computed(() => store.getters['circle/circles'])
const chronology = computed(() => {
  const result = []

  for (const circle of circles.value) {
    const events = circle.points.filter(p => p.peak === true)
    result.push({label: circle.period.label, size: events.length})
  }
  result.reverse()
  return result
})

</script>