<template>
  <dialog
    ref="modal"
    class="modal_custom"
  >
    <div class="flex flex-col h-full">
      <div class="flex flex-col grow min-h-0">
        <div
          class="flex flex-row justify-between"
        >
          <h3>{{ sectionName }}</h3>
          <CrossButton
            @close="exitModal"
          />
        </div>
        <MainInformations
          ref="eventMainInformation"
          class="grow min-h-0 overflow-scroll"
          @event-section="onEventSection"
        />
        <Options
          ref="eventOptions"
          class="mt-4"
        />
      </div>
      <div class="grid grid-cols-2 mt-5">
        <DesignButton
          v-if="isEditing"
          :class="{
            'mr-2': isEditing
          }"
          text="Supprimer"
          @click="onDeleteEvent"
        />
        <DesignButton
          class="col-start-2"
          :class="{
            'ml-2': isEditing
          }"
          text="Enregistrer"
          @click="onSaveEvent"
        />
      </div>
    </div>
  </dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "../../../store/mutations-types.js";
import * as d3 from 'd3'

import MainInformations from "./MainInformations.vue"
import Options from "./Options.vue"
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import CrossButton from "@/components/Button.vue"
import eventEditingTypes from "@/shared/enums/eventEditingTypes.js"
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import utils from "@/libraries/utils.js"
import EyeFetch from "@/libraries/EyeFetch.js"
import DesignButton from '@/components/DesignButton.vue'

let idSelectedSection = null

export default {
  name: "ModalDialog",
  components: {
    MainInformations,
    Options,
    CrossButton,
    DesignButton
  },
  data: () => {
    return {
      sectionName: "",
      idCounter: 0,
      isEditing: false
    };
  },
  computed: {
    ...mapGetters({
      pieSelectedSections: "pie/pieSelectedSections",
      isEditMode: 'isEditMode',
      editEvent: 'event/edit/editingEventToAdd',
      editingType: 'event/edit/editingType',
      unitPerCircle: 'circle/unitPerCircle',
      periodUnit: 'circle/periodUnit',
      isInPatient: 'isInPatient',
      isInNurseEntry: 'isInNurseEntry',
      folderId: 'patient/folderId'
    })
  },
  watch: {
    isEditMode() {
      this.isEditing = false
      if (this.isEditMode === true) {
        this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, eventEditingTypes.ADD)
        this.idCounter = 0
        this.$refs["modal"].show()
        this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_EVENTS_TO_ADD)
      } else {
        this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, null)
        this.exitModal(null)
      }
    },
    editEvent() {
      if (this.editEvent !== null) {
        this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, 
          this.pieSelectedSections.length === 0
            ? eventEditingTypes.ALREADY_EXIST
            : eventEditingTypes.ADD
        )
        if (this.$refs.modal.open === false) {
          this.$refs["modal"].show()
        }
        this.$refs.eventMainInformation.editEvent(this.editEvent)
        this.$refs.eventOptions.editEvent(this.editEvent)
        this.isEditing = true
      }
    }
  },

  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      deleteEvent: 'event/edit/deleteEvent',
      deleteUserCache: 'deleteUserCache',
      getDataRepresentation: 'circle/getDataRepresentation'
    }),
    async onSaveEvent(event) {
      const mainData = this.$refs.eventMainInformation.getMainInformation()
      const options = this.$refs.eventOptions.getOptions()
      let newEvents = []

      if (mainData === null || options === null) {
        return
      }

      const eventCommonParams = {
        code: mainData.selectedEventCode,
        severity: +mainData.severity,
        defaultLabel: mainData.eventLabel,
        idSection: idSelectedSection,
        resourceType: mainData.resourceType,
        note: options.note || ''
      }

      if (this.editingType === eventEditingTypes.ADD && options.recurrence && options.recurrence.enable) {
        const dates = d3[options.recurrence.intervalUnit].range(
          d3[options.recurrence.intervalUnit].floor(new Date(options.recurrence.start)),
          new Date(options.recurrence.end),
          options.recurrence.interval
        )

        newEvents = dates.map(date => ({
          ...eventCommonParams,
          ...{
            id: `TMP-${this.idCounter++}`,
            date: date.toISOString()
          }
        }))
      } else {
        newEvents = [{
          ...eventCommonParams,
          ...{
            id: this.editingType === eventEditingTypes.ALREADY_EXIST ? this.editEvent.id : `TMP-${this.idCounter++}`,
            date: new Date(mainData.eventDate).toISOString(),
          }
        }]
      }

      this.$refs.eventMainInformation.resetForm()
      this.$refs.eventOptions.resetForm()

      if (event.isTrusted) {
        await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/patient/event`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            events: newEvents,
            folderId: this.folderId
          })
        })
      }
      this.sendEvent({event})

      await this.deleteUserCache()

      if (this.editingType === eventEditingTypes.ALREADY_EXIST) {
        this.exitModal(null)
      }

      this.getDataRepresentation({
        unitPerCircle: this.unitPerCircle,
        periodUnit: this.periodUnit,
        reloadDataTypes: reloadDataTypes.TEMPORALITY
      })

      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    async onDeleteEvent(event) {
      if (event.isTrusted) {
        await this.deleteEvent({
          idEvents: [this.editEvent.id]
        })
      }
      this.sendEvent({event})
      this.exitModal(null)
      await this.deleteUserCache()
      await this.getDataRepresentation({
        unitPerCircle: this.unitPerCircle,
        periodUnit: this.periodUnit,
        reloadDataTypes: reloadDataTypes.TEMPORALITY
      })
      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    exitModal(event) {
      if (event) {
        this.sendEvent({ event })
      }

      this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      this.$refs["modal"].close()
      this.$store.commit(`${mutationTypes.IS_EDIT_MODE}`, false)
      this.$refs.eventMainInformation.resetForm()
      this.$refs.eventOptions.resetForm()
      if (event) {
        this.collaborativeEventTreated()
      }
    },
    onEventSection(section) {
      idSelectedSection = section.id
      this.sectionName = section.name
    }
  },
};
</script>

<style>
.modal_custom {
  position: fixed;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: var(--color-bg-1);
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  max-width: 700px;
  width: 50vw;
  height: 50vh;
  min-width: 30vw;
  min-height: 50vh;
  resize: both;
  overflow: auto;
  color: var(--color-text)
}

.input_wrapper {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}
.modal_form {
  display: flex;
  flex-direction: column;
}

/* .modal_custom button:hover {
  background: rgb(86, 97, 109);
  transition-duration: 500ms;
} */
.modal_custom button:nth-child(1) {
  margin-right: 2px;
}
.modal_custom button:nth-child(2) {
  margin-left: 2px;
}
.button_wrapper {
  display: flex;
  margin-top: 20px;
}
textarea {
  margin-bottom: 15px;
  resize: vertical;
}
dialog::backdrop {
  background: linear-gradient(90deg, navy,rgb(1, 1, 47) );
  opacity: 0.1;
}

#suggestions-modal {
  margin-top: 20px;
  margin-inline: 5px;
}

.suggestion {
  cursor: pointer;
}

.error-modal {
  color: red;
}

/* .insert-event-recurrence-choose-dates { */
input:invalid {
  border: red solid 1px;
}

</style>