<template>
  <form @submit.prevent="createAccount">
    <p class="mb-4">
      Les identifiants de connexion seront automatiquement transmis au destinataire par e-mail.
    </p>
    <div class="field-block">
      <label>
        <span class="block">Nom</span>
        <input
          v-model="lastName"
          type="text"
          class="text-input"
          @input="(e) => onInput(e, lastName, false)"
        >
        <p
          v-for="(error, index) in errors.lastName"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </label>
    </div>
    <div class="field-block">
      <label>
        <span class="block">Prénom</span>
        <input
          v-model="firstName"
          type="text"
          class="text-input"
          @input="(e) => onInput(e, firstName, false)"
        >
        <p
          v-for="(error, index) in errors.firstName"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </label>
    </div>
    <div class="field-block">
      <label>
        <span class="block">Email</span>
        <input
          v-model="email"
          type="email"
          class="text-input"
          @input="(e) => onInput(e, email, false)"
        >
        <p
          v-for="(error, index) in errors.email"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </label>
    </div>
    <div>
      <DesignButton
        id="create-patient-validate"
        text="Valider"
      />
    </div>
  </form>
</template>

<script>
export default {
  name: 'AccountCreationForm'
}
</script>

<script setup>
import DesignButton from '@/components/DesignButton.vue'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import UserRole from '@/shared/enums/userRole.js'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const lastName = ref("")
const firstName = ref("")
const email = ref("")

const errors = ref({})

const userData = computed(() => store.getters['user/userData'])

function checkForm() {
  const requiredField = 'Ce champ est obligatoire'
  errors.value = {
    lastName: [],
    firstName: [],
    email: []
  }

  if (lastName.value === "") {
    errors.value.lastName.push(requiredField)
  }
  if (firstName.value === "") {
    errors.value.firstName.push(requiredField)
  }
  if (email.value === "") {
    errors.value.email.push(requiredField)
  }
}

async function createAccount(event) {
  checkForm()

  for (const err of Object.values(errors.value)) {
    if (err.length > 0) {
      return
    }
  }

  const accountObj = {
    surname: lastName.value,
    name: firstName.value,
    email: email.value,
  }

  if (userData.value.Role.includes(UserRole.CREATE_ACCOUNT)) {
    accountObj.permissions = []
  }

  if (event.isTrusted) {
    await store.dispatch('user/createAccount', accountObj)
    await store.dispatch('user/getUserData')
  }

  store.dispatch('ws/sendEvent', {event: event})
  router.push({ name: 'Home' })
  store.dispatch('ws/collaborativeEventTreated')
}

function onInput(event, text, radio) {
  store.dispatch('ws/sendEvent', {
    event: event,
    params: {
      text: text,
      change: radio === true
    }
  })

  store.dispatch('ws/collaborativeEventTreated')
}
</script>

<style scoped>
form {
  width: 350px;
}

#create-patient-validate {
  margin: unset;
  width: 100%;
}

.text-input {
  @apply py-1 px-1;
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
}

label > span {
  @apply mb-1;
  font-size: 1.1rem;
}

label {
  font-size: 1.2rem;
}

.field-block {
  @apply mb-6;
}
</style>