<template>
  <div>
    <ul>
      <li
        v-for="(event, index) in events"
        :key="index"
        class="mb-2"
      >
        {{ event.size }} {{ event.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LegendCircle'
}
</script>

<script setup>

import EventManager from '@/libraries/EventManager.js'
import { computed } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'

const store = useStore()

const hoveredCircle = computed(() => store.getters['circle/hoveredCircle'])
const events = computed(() => {
  let result = []
  if (hoveredCircle.value !== null) {
    let events = EventManager.extractEventsFromCircle([hoveredCircle.value])
    events = _.groupBy(events, (event) => event.code)
    for (const groupedEvents of Object.values(events)) {
      result.push({label: groupedEvents[0].defaultLabel, size: groupedEvents.length})
    }
    result.sort((a, b) => b.size - a.size)
  }
  return result
})

</script>