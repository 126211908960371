<template>
  <ModalError />
  <Visio />
  <CollaborativeViewerLayer />
  <Replay v-if="replay" />
  <div id="root-app">
    <a
      v-if="isOnAppendicesPage"
      id="appendices-home-button"
      href="#"
      @click.prevent="onAppendicesHomeButton"
    >
      Accueil
    </a>
    <fontManager :display-option="false" />
    <Cursor v-if="showCursor" />
    <router-view />
    <WeveyesEyediagLogo
      v-if="isOnAppendicesPage"
      class="weveyes-eyediag-logo"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Visio from '@/components/Visio.vue'
import CollaborativeViewerLayer from '@/components/CollaborativeViewerLayer.vue'
import Cursor from "@/components/Cursor.vue"
import fontManager from "@/components/fontManager.vue"
import { initContextMenu } from '@/config/contextMenu/contextMenu.js'
import Replay from '@/components/record/Replay.vue'
import WeveyesEyediagLogo from "@/assets/icons/weveyesEyediag.vue"
import { useE2e } from '@/robot/e2eTesting.js'
import ModalError from '@/components/modal/ModalError.vue'

export default {
  name: "App",
  components: {
    Visio,
    CollaborativeViewerLayer,
    Cursor,
    fontManager,
    Replay,
    WeveyesEyediagLogo,
    ModalError
  },
  setup() {
    return useE2e()
  },
  computed: {
    ...mapGetters({
      loggedIn: 'user/loggedIn',
      replay: 'record/replay/replay',
      record: 'record/record/record'
    }),
    /**
     * Indique si le curseur du présentateur doit être affiché lors d'une session collaborative
     * @type {Boolean}
     */
    showCursor() {
      return this.$store.state.ws.showCursor
    },
    currentPage() {
      return this.$router.currentRoute.value.name
    },
    isOnAppendicesPage() {
      return ['ImportView', 'PatientCreation'].includes(this.currentPage)
    }
  },
  watch: {
    loggedIn() {
      if (this.loggedIn === true) {
        this.initWs()
      }
    }
  },
  created() {
    initContextMenu(this.$store)
    const mainTheme = localStorage.getItem('mainTheme')
    if (mainTheme) {
      document.documentElement.classList.add(mainTheme);
    }
  },
  mounted() {
    document.addEventListener("visibilitychange", this.visibilityChange)
  },
  beforeUnmount() {
    document.removeEventListener("visibilitychange", this.visibilityChange)
  },
  methods: {
    ...mapActions({
      initWs: "ws/initWs",
      changeTabVisibility: "ws/changeTabVisibility",
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    visibilityChange() {
      const visible = document.visibilityState === 'visible'
      this.changeTabVisibility({
        visible: visible
      })
      if (visible === false && this.record === true) {
        this.$store.dispatch('record/record/sendRecordChunk', {exitBrowser: true})
      }
    },
    onAppendicesHomeButton(event) {
      this.sendEvent({event: event})
      this.$router.push({name: 'Home'})
      this.collaborativeEventTreated()
    }
  }
};
</script>

<style>
@import './assets/main.css';

#root-app {
  height: 100vh;
  width: 100vw;
}

#appendices-home-button {
  position: absolute;
  left: 40px;
  top: 20px;
  font-size: 1.2rem;
  color: var(--color-text);
}

.weveyes-eyediag-logo {
  position: absolute;
  bottom: 20px;
  left: 40px;
  transform-origin: bottom left;
  transform: scale(0.13);
}
</style>
