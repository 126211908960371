<template>
  <ListBase
    id="list-nurse-entry"
    ref="list-base"
  >
    <div id="list-nurse-entry-container">
      <div
        v-for="(idSection) in idsSections"
        :key="idSection"
        class="mt-2"
      >
        <TooltipForm
          ref="entry-form"
          class="relative tooltip-style tooltip-list-form"
          :id-section="idSection"
          :hovered-section="hoveredSectionsName.length > 0 ? hoveredSectionsName.includes(idSection) : null"
          :tooltip-data="{}"
        />
        <div
          v-if="placeHolders[idSection] !== undefined"
          ref="place-holder"
          class="placeholder-tooltip-form"
          :id-section="idSection"
          :style="`height: ${placeHolders[idSection].height}px;`"
        />
      </div>
    </div>
  </ListBase>
</template>

<script>
import ListBase from '@/components/borderLists/ListBase.vue'
import { mapGetters, mapActions } from 'vuex'
import TooltipForm from '../../diabetologie/nurseForm/TooltipForm.vue'
import * as d3 from 'd3'
import Utils from '@/libraries/utils.js'
import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import EyeFetch from '@/libraries/EyeFetch.js'

export default {
  name: 'ListNurseEntry',
  components: {
    ListBase,
    TooltipForm
  },
  data: () => ({
    idsSections: [],
    placeHolders: {}
  }),
  computed: {
    ...mapGetters({
      currentDisplayedBorderList: 'currentDisplayedBorderList',
      hoveredEvent: "event/common/hoveredEvent",
      pieHoveredSections: "pie/pieHoveredSections",
      pieSelectedSections: "pie/pieSelectedSections",
      isLockedDataEntry: 'formEntry/isLocked',
      sections: 'sections',
      hierarchy: 'hierarchy',
      isInNurseEntry: 'isInNurseEntry',
      lockedPie: 'formEntry/lockedPie',
      lockedEvent: 'formEntry/lockedEvent',
      savingFieldEvent: 'formEntry/savingFieldEvent',
      folderId: 'patient/folderId'
    }),
    hoveredSectionsName() {
      //La mise en surbrillance d'un element particulier à entrer est prioritaire par rapport à la mise en surbrillance de l'ensemble du formulaire par rapport au quartier de la pie selectionné
      //Pour les sections, la mise en surbrillance des sections selectionné (locké) sont prioritaire par rapport aux sections survolés
      //Pour les événements, la mise en surbrillance de la section de l'événement séléctionné est prioritaire par rapport à l'événement survolé
      if (this.hoveredEvent !== null || this.lockedEvent !== null) {
        const event = this.lockedEvent || this.hoveredEvent
        return [event.Section]
      } else {
        const sections = this.pieSelectedSections.length > 0
          ? this.pieSelectedSections
          : this.pieHoveredSections
  
        if (sections.length > 0 && this.hoveredEvent === null) {
          const sortedItems = [...sections].sort((a, b) => a.id - b.id)
          return sortedItems.map(section => section.name)
        }
        //Cas particulier si elem cliqué
        return []
      }
    }
  },
  watch: {
    async hoveredEvent(_, oldHoveredEvent) {
      if (this.isInNurseEntry) {
        //Verifier que c'est bien un dataEntry ?
        if (this.hoveredEvent === null) {
          this.blurTooltipField()
          const hoveredForm = this.$refs['entry-form'].find(f => f.idSection === oldHoveredEvent.Section)
          if (hoveredForm) {
            hoveredForm.fillTooltipFields(true, true)
          }
        }
        
        //this.$refs['entry-form'] dans le if car dans le cas d'une note sauvegardé par un utilisateur pour un patient, lorsque l'on veut voir l'état de la note. Les requêtes recupérant les données de formulaire n'ont pas encore fini et this.$refs['entry-form'] n'existe pas
        if (this.$refs['entry-form'] && this.hoveredEvent !== null) { //&& this.isLockedDataEntry === false) {
          const hoveredForm = this.$refs['entry-form'].find(f => f.idSection === this.hoveredEvent.Section)
  
          if (hoveredForm) {
            await Utils.scrollIntoViewAndWait(hoveredForm.$el, this.$refs['list-base'].$el)
            hoveredForm.fillTooltipFields(true, false)
            this.addTabListener()
          }
        }
      }
    },
    async pieHoveredSections(_, oldPieHoveredSections) {
      if (this.isInNurseEntry && this.isLockedDataEntry === false) {
        if (this.pieHoveredSections.length === 0) {
          this.blurTooltipField()
        }
        this.$store.commit(`formEntry/${mutationTypes.SET_CONFIG_BOX_ARROW}`, null)
        this.addTabListener()
        await this.refreshListSectionsAndHighlight()

        let forms = this.$refs['entry-form'].filter(f => oldPieHoveredSections.find(s => s.name === f.idSection))
        for (const form of forms) {
          form.fillTooltipFields(true, true)
        }
        forms = this.$refs['entry-form'].filter(f => this.pieHoveredSections.find(s => s.name === f.idSection))
        for (const form of forms) {
          form.fillTooltipFields(true, false)
        }
      }
    },
    'hierarchy.id': {
      handler() {
        //Sinon quand les sections change pour une autre classif entrée de l'info, les sections avec le même nom n'ont pas leur forme qui se refresh (ex Cardiovasculaire)
        this.idsSections = []
      }
    },
    sections() {
      this.idsSections = this.isInNurseEntry
        ? this.sections.map(section => section.name)
        : []
    },
    pieSelectedSections(_, oldSelectedSections) {
      if (this.isInNurseEntry) {
        this.onLockElem(
          oldSelectedSections.map(s => s.name),
          this.pieSelectedSections.map(s => s.name)
        )
        this.removeTabListener()
        this.focusTooltip()
        if (this.lockedPie === null) {
          this.blurTooltipField()
        }
      }
    },
    async lockedEvent(_, oldLockedEvent) {
      this.onLockElem(
        oldLockedEvent ? [oldLockedEvent.Section] : [],
        this.lockedEvent ? [this.lockedEvent.Section] : []
      )
      this.removeTabListener()
      this.focusTooltip()
      if (this.lockedEvent === null) {
        this.blurTooltipField()
      }
    },
    savingFieldEvent() {
      if (this.savingFieldEvent !== null) {
        this.onSaveData(this.savingFieldEvent)
      }
    }
  },
  methods: {
    ...mapActions({
      blurTooltipField: 'formEntry/blurTooltipField',
      deleteUserCache: 'deleteUserCache',
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      getDataRepresentation: 'circle/getDataRepresentation'
    }),
    /**
     * Cette fonction est appelé pour initier le déplacement de la liste / panel (ouverture et fermeture de celui-ci)
     * @param  {...any} params Liste des paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    async refreshListSectionsAndHighlight() {
      const sectionsName = this.sections.map(section => section.name)

      this.idsSections = this.hoveredSectionsName.length > 1
        ? [...this.hoveredSectionsName, ...sectionsName.filter(s => this.hoveredSectionsName.includes(s) === false)]
        : sectionsName

      await this.$nextTick()
      await this.onPieSectionSelected()
    },
    async onPieSectionSelected() {
      if (this.$refs['entry-form'] && this.pieHoveredSections.length > 0) {
        //On cherche dans idsSections et non this.$refs['entry-form'] car l'ordre des $refs n'est pas garantit d'être le même que l'ordre du tableau source (idsSections)
        let hoveredForm = this.idsSections.find(section => this.pieHoveredSections.find(s => s.name === section))
        
        if (hoveredForm) {
          hoveredForm = this.$refs['entry-form'].find(f => f.idSection === hoveredForm)
        }

        if (hoveredForm) {
          await Utils.scrollIntoViewAndWait(hoveredForm.$el, this.$refs['list-base'].$el)
        }
      }
    },
    async onLockElem(oldSections, newSections) {
      let promises = []

      try {
        for (const section of oldSections) {
          promises.push(this.findAndAnimateTooltipLockStatus(false, section))
        }
        await Promise.all(promises)
      } catch {}

      try {
        promises = []
        await this.refreshListSectionsAndHighlight()
        await this.$nextTick()

        this.calculatePositionsPlaceHolders(newSections)
        for (const section of newSections) {
          promises.push(this.findAndAnimateTooltipLockStatus(true, section))
        }
        await Promise.all(promises)
      } catch {}
    },
    async findAndAnimateTooltipLockStatus(toLock, idSection) {
      const hoveredForm = this.getTooltipDomElem(idSection)

      if (hoveredForm === undefined) {
        return null
      }
      const func = toLock ? 'lockTooltipAnimation' : 'unlockTooltipAnimation'
      return this[func](hoveredForm, idSection)
    },
    async lockTooltipAnimation(hoveredForm, idSection) {
      d3.select(this.$refs['list-base'].$el).style('overflow', 'hidden')

      return d3.select(hoveredForm.$el)
        .style('width', `${this.placeHolders[idSection].width}px`)
        .style('position', 'absolute')
        .style('right', '5px') //5 correspond à la marge de chaque coté de la liste
        .style('top', `${this.placeHolders[idSection].top}px`)
        .transition()
        .duration(200)
        .style('right', '30px')
        .end()
    },
    async unlockTooltipAnimation(hoveredForm, idSection) {
      await d3.select(hoveredForm.$el)
        .transition()
        .duration(200)
        .style('right', '5px')
        .end()
      d3.select(hoveredForm.$el)
        .style('right', '0px')
        .style('top', '0px')
        .style('position', 'relative')
        .style('width', 'auto')

      this.placeHolders[idSection].resizeObserver.unobserve(hoveredForm.$el)
      delete this.placeHolders[idSection]
      d3.select(this.$refs['list-base'].$el).style('overflow', 'scroll')
    },
    calculatePositionsPlaceHolders(sections) {
      for (const section of sections) {
        const hoveredForm = this.getTooltipDomElem(section)
        const rectElem = hoveredForm.$el.getBoundingClientRect()
        const width = hoveredForm.$el.offsetWidth - 20 //les 20 correspondent au padding de la cass tooltip-style
        const resizeObserver = this.createTooltipResizeObserver()

        resizeObserver.observe(hoveredForm.$el)

        this.placeHolders[section] = {
          height: hoveredForm.$el.offsetHeight,
          width: width,
          top: rectElem.top,
          resizeObserver: resizeObserver
        }
      }
    },
    focusTooltip() {
      let hoveredForm = null

      if (this.lockedPie !== null || this.hoveredSectionsName.length > 0) {
        const nameSection = this.lockedPie !== null
          ? this.lockedPie.name
          : this.hoveredSectionsName[0]

          
        hoveredForm = this.getTooltipDomElem(nameSection)
        if (hoveredForm !== undefined) {
          hoveredForm.setFocusOnFirst(null)
        }
      } else if (this.hoveredEvent !== null || this.lockedEvent !== null) {
        const event = this.lockedEvent || this.hoveredEvent
        hoveredForm = this.getTooltipDomElem(event.Section)
        if (hoveredForm !== undefined) {
          hoveredForm.setFocusOnFirst(event.extension.IdGroupe)
        }
      }
    },
    addTabListener() {
      d3.select(document).on('keydown.tab', (event) => {
        if (event.key === "Tab") {
          event.preventDefault()
          this.focusTooltip()
          this.removeTabListener()
        }
      })
    },
    removeTabListener() {
      d3.select(document).on('keydown.tab', null)
    },
    getTooltipDomElem(idSection) {
      return this.$refs['entry-form'].find(f => f.idSection === idSection)
    },
    createTooltipResizeObserver() {
      return new ResizeObserver(async (entries) => {
        for (const entry of entries) {
          const idSection = entry.target.getAttribute('id-section')
          this.placeHolders[idSection].height = entry.target.offsetHeight
          await this.$nextTick()
          this.resizeLockedTooltip()
        }
      })
    },
    resizeLockedTooltip() {
      for (const placeHolder of this.$refs['place-holder']) {
        const idSection = placeHolder.getAttribute('id-section')
        const ttipElem = this.getTooltipDomElem(idSection)

        if (ttipElem !== undefined) {
          const rectList = this.$refs['list-base'].$el.getBoundingClientRect()
          const rect = placeHolder.getBoundingClientRect()
          let top = rect.top

          //Cas pour la dernière tooltip de la liste, en dépliant des inputs facultatif, la taille de la tooltip augmente et il est possible que la taille de la tooltip dépasse le bas de la page et soit inaccessible. Cette condition permet de remonter la tooltip en absolute et scroll la liste pour afficher entièrement le slot dédié à la tooltip absolute
          if (rect.bottom > rectList.bottom) {
            const toRemove = rect.bottom - rectList.bottom
            top -= toRemove
            this.$refs['list-base'].$el.scrollTop += toRemove
          }
          d3.select(ttipElem.$el)
            .style('top', `${top}px`)
        }
      }
    },
    async saveData(events) {
      //Enregistrement seulement par le présentateur
      for (const e of events) {
        const date = e.date ? new Date(e.date) : new Date()
        e.resourceType = 'EyeCustom'
        e.idSection = e.section
        e.date = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
      }

      await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/patient/event`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          events: events,
          folderId: this.folderId
        })
      })
    },
    async onSaveData(event) {
      const events = []

      for (const ttipForm of this.$refs['entry-form']) {
        const data = await ttipForm.getDataToSave()
        events.push(...data)
        ttipForm.clearEditedFields()
      }

      if (event.isTrusted) {
        await this.saveData(events)
      }

      //S'arranger pour focer à faire une validation click souris (plus simple)
      this.sendEvent({
        event: {
          type: 'click'
        },
        currentTarget: document.getElementById('nurse-entry-save-button')
      })
      await this.deleteUserCache()

      this.$store.commit(`pie/${mutationTypes.RESET_SELECTED_SECTION}`)
      this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_EVENT}`, null)
      this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_PIE}`, null)
      this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, null)
      this.$store.commit(`formEntry/${mutationTypes.SET_CONFIG_BOX_ARROW}`, null)
      this.$store.commit(`formEntry/${mutationTypes.SET_SAVING_FIELDS_EVENT}`, null)
      
      await this.getDataRepresentation({
        unitPerCircle: this.$store.state.circle.unitPerCircle,
        periodUnit: this.$store.state.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION
      })

      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
.tooltip-list-form {
  background-color: var(--color-bg-0);
  z-index: 8;
}
</style>

<style>
#list-nurse-entry .style-borderList-info{
  margin-inline: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>