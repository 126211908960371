<template>
  <div>
    <div
      v-for="(event, index) in events"
      :key="index"
    >
      <ListItem
        :event="event"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegendPie'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import ListItem from '@/components/event/ListItem.vue'
import EventSelectionTypes from '@/enums/event_selection_types.js'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()

const events = ref([])
const displayedEvents = computed(() => store.getters['event/common/events'])
const pieHoveredSections = computed(() => store.getters['pie/pieHoveredSections'])

watch(pieHoveredSections, () => {
  setLegendEvents()
})

onMounted(() => {
  setLegendEvents()
})

onBeforeUnmount(() => {
  deleteOldHighlight(events.value)
  events.value = []
  store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, displayedEvents.value)
})

function setLegendEvents() {
  deleteOldHighlight(events.value)
  if (pieHoveredSections.value.length > 0) {
    const idPie = pieHoveredSections.value[pieHoveredSections.value.length - 1].id
    events.value = displayedEvents.value.filter(event => event.circleParent.active && event.parentSection === idPie)
  }
  highlight(events.value)
  store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, displayedEvents.value)
  return []
}

function deleteOldHighlight(src) {
  for (const event of src) {
    store.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
      event,
      selectionType: EventSelectionTypes.LEGEND
    })
  }
}

function highlight(src) {
  for (const event of src) {
    store.commit(`event/common/${mutationTypes.ADD_SELECTED_EVENT}`, {
      event: event,
      selectionType: EventSelectionTypes.LEGEND
    })
  }
}

</script>