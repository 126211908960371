<template>
  <div>
    <div>
      <div
        class="cursor-pointer"
        @click="onClickDiab"
      >
        <p
          v-if="diabEvent"
        >
          {{ diabEvent.defaultLabel }}
        </p>
        <p
          v-else
        >
          Diabète non spécifié
        </p>
      </div>
    </div>
    <div>
      <GenericModal
        :id="id"
        :title="title"
        :description="description"
        :buttons="buttons"
        theme="success"
        @close="onCancel"
      >
        <div class="flex flex-col mb-2">
          <div class="flex flex-col">
            <label>Date</label>
            <input
              v-model="date"
              type="date"
              @change="e => onChangeValue(e, date)"
            >
          </div>
          <div class="flex flex-col mt-2">
            <label>Type</label>
            <select
              v-model="diab_main_selected"
              @change="e => onChangeValue(e, diab_main_selected)"
            >
              <option 
                v-for="item in diab_main"
                :key="item.code"
                :value="item.code"
              >
                {{ item.in }}
              </option>
            </select>
          </div>
          <div class="flex flex-col mt-2">
            <label>Complication</label>
            <select
              v-model="diab_plus_selected"
              @change="e => onChangeValue(e, diab_plus_selected)"
            >
              <option 
                v-for="item in diab_plus"
                :key="item.code"
                :value="item.code"
              >
                {{ item.in }}
              </option>
            </select>
          </div>
          <div
            v-if="diab_main_selected == 'E11.'"
            class="flex flex-col mt-2"
          >
            <label>Traitement</label>
            <select
              v-model="diab_ttti_selected"
              @change="e => onChangeValue(e, diab_ttti_selected)"
            >
              <option
                v-for="item in diab_ttti"
                :key="item.code"
                :value="item.code"
              >
                {{ item.in }}
              </option>
            </select>
          </div>
        </div>
      </GenericModal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GenericModal from '@/components/modal/GenericModal.vue'
import EyeFetch from '@/libraries/EyeFetch'

export default {
  name: 'SelectDiabeteMenu',
  components: {
    GenericModal
  },
  data: () => ({
    diab_main:[{ in:"Diabète sucré de type 1", code:"E10."}, { in:"Diabète sucré de type 2", code:"E11."}, { in:"Diabète sucré de malnutrition", code:"E12."}, { in:"Autres diabètes sucrés précisés", code:"E13."}, { in:"Diabète sucré  sans précision", code:"E14."}],
    diab_plus:[{ in:"coma", code:"0"}, { in:"acidocétose", code:"1"}, { in:"complications rénales", code:"2"}, { in:"complications oculaires", code:"3"}, { in:"complications neurologiques", code:"4"}, { in:"complications vasculaires périphériques", code:"5"}, { in:"autres complications précisées", code:"6"}, { in:"complications multiples", code:"7"}, { in:"sans complication", code:"9"}],
    diab_ttti:[{ in:"insulinotraité", code:"0"}, {in:"non insulinotraité ou sans précision", code:"8"}],
    diab_main_selected: null,
    diab_plus_selected: null,
    diab_ttti_selected: null,
    date: null,
    /**
     * Titre de la modal
     * @type {String}
     */
    title: 'Caractérisation diabète',
    /**
     * Description de la modal
     * @type {String}
     */
    description: '',
    /**
     * Tableau décrivant les boutons présent sur la modal
     * @type {EyeGenericModalButton[]}
     */
    buttons: [],
    /**
     * Identifiant de la modal générique
     * @type {String}
     */
    id: "modal-select-diab"
  }),
  computed: {
    ...mapGetters({
      events: 'event/common/events',
      diabEvent: 'medical/diabete/diabEvent',
      folderId: 'patient/folderId'
    })
  },
  watch: {
    diab_main_selected() {
      if (this.diab_plus_selected && this.diab_main_selected != "E11.") {
        this.diab_ttti_selected = null
      }
    },
    diab_plus_selected() {
      if (this.diab_main_selected && this.diab_main_selected != "E11.") {
        this.diab_ttti_selected = null
      }
    }
  },
  async mounted() {
    this.setButtons()
    this.date = (new Date).toISOString().slice(0, 10)
    await this.getDiabInfo()
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      getDiabInfo: 'medical/diabete/getDiabInfo',
      deleteUserCache: 'deleteUserCache'
    }),
    getDiabCode() {
      if (this.diab_main_selected == "E11." && this.diab_ttti_selected) {
        return this.diab_main_selected + this.diab_plus_selected + this.diab_ttti_selected
      } else {
        return this.diab_main_selected + this.diab_plus_selected
      }
    },
    /**
     * Cette fonction permet de définir les boutons qui seront présent sur la modal
     */
    setButtons() {
      this.buttons = [
        {
          label: 'Valider',
          backgroundColorTheme: 'success',
          color: 'white',
          onClick: this.onValidateDiab
        },
        {
          label: 'Annuler',
          backgroundColorTheme: 'error',
          color: 'white',
          onClick: this.onCancel
        }
      ]
    },
    onClickDiab(event) {
      this.sendEvent({event: event})
      const elem = document.getElementById(this.id)
      if (elem.open === false) {
        elem.showModal()
      }
      this.collaborativeEventTreated()
    },
    onChangeValue(event, text) {
      this.sendEvent({
        event: event,
        params: {
          text: text,
          change: true
        }
      })
      this.collaborativeEventTreated()
    },
    async onValidateDiab(event) {      
      if (event.isTrusted) {
        const date = new Date(this.date)
        const diabEvent = {
          code: this.getDiabCode(),
          date: (date.toString() !== 'Invalid Date')
            ? date.toISOString()
            : (new Date).toISOString(),
          resourceType: 'Condition',
          note: ''
        }
        await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/patient/event`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            events: [diabEvent],
            folderId: this.folderId
          })
        })
      }
      this.sendEvent({event: event})
      await this.deleteUserCache()
      await this.getDiabInfo()

      const elem = document.getElementById(this.id)
      if (elem) {
        elem.close()
      }
      this.collaborativeEventTreated()
    },
    onCancel(event) {
      this.sendEvent({event: event})
      const elem = document.getElementById(this.id)
      elem.close()
      this.collaborativeEventTreated()
    }
  }
}
</script>