<template>
  <div>
    <div
      id="editModeOption"
      @click="onClickEditModeOption"
    >
      <EditIcon
        width="30px"
        height="30px"
      />
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'
import EditIcon from '@/assets/icons/edit.vue'

const store = useStore()

function onClickEditModeOption(event) {
  store.dispatch('ws/sendEvent', {event: event})
  store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, store.getters['isEditMode'] ? "" : "Edition")
  store.commit(`${mutationTypes.IS_EDIT_MODE}`)
  store.dispatch('ws/collaborativeEventTreated')
}
</script>

<style scoped>
#editModeOption {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
</style>