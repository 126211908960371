<template>
  <div class="selected-event">
    <div
      class="event-circle-container" 
      style="width: 50px"
    >
      <span
        :class="{
          [`dot-${getEventResourceTypeColor(event)}`]: true,
          [`dot-fill-${getEventResourceTypeColor(event)}`]: hoveredEvent && event.id === hoveredEvent.id,
        }"
        :style="`width: ${event.r * 2}px; height: ${event.r * 2}px`"
      />
    </div>

    <p class="selected-event-text">
      {{ event.defaultLabel }}
    </p>
  </div>
</template>

<script setup>

import { defineProps, computed } from 'vue'
import { useStore } from 'vuex'
import utils from '@/libraries/utils.js'

const store = useStore()
const props = defineProps({
  event: {
    type: Object,
    required: true
  }
})

const hoveredEvent = computed(() => store.getters['event/common/hoveredEvent'])

const getEventResourceTypeColor = (event) => utils.getEventResourceTypeColor(event, store)

</script>

<style scoped>
.dot-condition {
  background-color: none;
  border-radius: 50%;
  border: 2px solid var(--color-primary-accent-opaque);
  display: inline-block;
}

.dot-procedure {
  background-color: none;
  border-radius: 50%;
  border: 2px solid var(--color-secondary-accent-opaque);
  display: inline-block;
}

.dot-immunization, .dot-allergy {
  background-color: none;
  border-radius: 50%;
  border: 2px solid var(--c-green-1);
  display: inline-block;
}

.dot-default {
  background-color: none;
  border-radius: 50%;
  border: 2px solid var(--color-primary-accent-opaque);
  display: inline-block;
}

.dot-fill-condition {
  background-color: var(--color-primary-accent-trsp);
}

.dot-fill-default {
  background-color: var(--color-primary-accent-trsp);
}

.dot-fill-procedure {
  background-color: var(--color-secondary-accent-trsp);
}

.dot-fill-immunization, .dot-fill-allergy {
  background-color: var(--c-green-1);
}

.event-circle-container{
  display: flex;
  justify-content: right;
}

.selected-event-text {
  display: inline-block;
  padding: 0px;
  margin-left: 10px;
  font-size: 1.2rem;
  width: calc(100% - 60px);
  text-align: justify-all;
}

.selected-event {
  padding-bottom: 10px;
  display: inline-grid;
  grid-template-columns: 1fr 4fr;
  display: flex;
}
</style>