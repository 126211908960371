<template>
  <ListBase
    id="legend-list"
    ref="list-base"
  >
    <component
      :is="currentContent"
      v-if="currentContent !== null"
    />
  </ListBase>
</template>

<script setup>
import { defineExpose, ref, computed, watch, markRaw } from 'vue'
import { useStore } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'

import ContentCircle from '@/components/borderLists/ListLegend/content/Circle.vue'
import ContentEvent from '@/components/borderLists/ListLegend/content/Event.vue'
import ContentPie from '@/components/borderLists/ListLegend/content/Pie.vue'
import ContentRepCircle from '@/components/borderLists/ListLegend/content/RepCircle.vue'
import borderListTypes from '@/enums/borderList/borderListTypes.js'

const store = useStore()
const currentContent = ref(null)

const hoveredEvent = computed(() => store.getters['event/common/hoveredEvent'])
const hoveredCircle = computed(() => store.getters['circle/hoveredCircle'])
const pieHoveredSections = computed(() => store.getters['pie/pieHoveredSections'])
const isHoveredRepCircle = computed(() => store.getters['circle/isHoveredRepCircle'])
const isLegendShow = computed(() => store.getters['borderList/currentDisplayedBorderList'] === borderListTypes.LIST_LEGEND)

watch(isLegendShow, () => {
  if (isLegendShow.value === false) {
    currentContent.value = null
  }
})

watch(hoveredEvent, () => {
  if (isLegendShow.value === true && hoveredEvent.value !== null) {
    currentContent.value = markRaw(ContentEvent)
  }
})

watch(hoveredCircle, () => {
  if (isLegendShow.value === true && hoveredCircle.value !== null && hoveredEvent.value === null) {
    currentContent.value = markRaw(ContentCircle)
  }
})

watch(pieHoveredSections, () => {
  if (isLegendShow.value === true && pieHoveredSections.value.length > 0) {
    currentContent.value = markRaw(ContentPie)
  }
})

watch(isHoveredRepCircle, () => {
  if (isLegendShow.value === true && isHoveredRepCircle.value === true) {
    currentContent.value = markRaw(ContentRepCircle)
  }
})

function movePanel(...params) {
  this.$refs['list-base'].movePanel(...params)
}

defineExpose({
  movePanel: movePanel
})

</script>