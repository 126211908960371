<template>
  <div>
    <form
      method="dialog"
      class="modal_form relative flex flex-col h-full"
      @submit="e => e.preventDefault()"
    >
      <div class="flex flex-row">
        <div class="flex flex-col basis-0 grow mr-1.5">
          <label class="label-box">Sévérité</label>
          <input
            ref="severity-input"
            v-model="severity"
            type="number"
            max="4"
            min="1"
            pattern="[1-4]{1}"
            name="severity"
            required
          >
          <p
            v-for="(error, index) in errors.severity"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </div>
        <div class="flex flex-col basis-0 grow ml-1.5">
          <div class="flex label-box items-center date-container">
            <label>Date</label>
            <p
              class="cursor-pointer underline underline-offset-2" 
              @click="setCurrentDate"
            >
              Date du jour ↓
            </p>
          </div>
          <input
            ref="date-input"
            v-model="eventDate"
            type="date"
            name="date"
            required
          >
          <p
            v-for="(error, index) in errors.date"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </div>
      </div>
      <div class="mt-4 flex flex-col basis-0">
        <label class="label-box">Evénement</label>
        <input
          ref="input-diag"
          v-model="eventLabel"
          required
          placeholder="Diabète"
        >
      </div>
      <p
        v-for="(error, index) in errors.event"
        :key="index"
        class="error-modal"
      >
        {{ error }}
      </p>
      <div class="mt-4">
        <p class="mb-2">
          Mots clefs
        </p>
        <div class="motclef-row">
          <div
            v-for="(mot, index) in mots_clefs"
            :key="index"
            class="mr-2"
          >
            <DesignButton 
              class="w-10"
              :text="mot"
              @click="(e) => updateEventLabel(e, mot)"
            />
          </div>
        </div>
      </div>
      <div
        id="suggestions-modal"
        ref="suggestions-modal"
        class="flex flex-col grow min-h-0"
      >
        <ul>
          <li
            v-for="(suggestion, index) in suggestions"
            :key="index"
            class="suggestion relative pr-4 pb-2 flex"
            @click="(e) => selectedSuggestion(e, suggestion)"
          >
            {{ `[${suggestion.code}] ${suggestion.defaultLabel}` }}
            <button
              class="p-0 bg-transparent border-0 absolute right-0"
              @click="(e) => onShowAssociatedEvents(e, suggestion.code)"
            >
              🔗
            </button>
          </li>
          <li
            v-if="suggestions.length === 0"
          >
            Aucune suggestion disponible
          </li>
        </ul>
      </div>
      <div
        v-if="isAnySuggestionSelected === false"
        class="mt-4 flex flex-col"
      >
        <label class="label-box">Catégorie</label>
        <select
          v-model="selectedSection"
          class="grow"
          @change="(e) => onSelectedSectionChange(e, selectedSection)"
        >
          <option
            v-for="section in sections"
            :key="section.id"
            :value="section.id"
          >
            {{ section.name }}
          </option>
        </select>
        <p
          v-for="(error, index) in errors.section"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </div>
    </form>
  </div>
</template>

<script>
import EyeFetch from '@/libraries/EyeFetch'
import * as d3 from 'd3'
import { mapGetters, mapActions } from 'vuex'
import DesignButton from '@/components/DesignButton.vue'

export default {
  name: 'AddEventModalMainInformations',
  components: {
    DesignButton
  },
  emits: ['eventSection'],
  data: () => ({
    selectedEventCode: null,
    eventLabel: "",
    severity: "1",
    eventDate: "",
    suggestions: [],
    errors: {
      severity: [],
      date: [],
      event: [],
    },
    isAnySuggestionSelected: false,
    selectedSection: 0,
    resourceType: 'EyeCustom',
    timeoutInterval: null,
    mots_clefs: [],
  }),
  computed: {
    ...mapGetters({
      editingType: 'event/edit/editingType',
      sections: 'sections'
    })
  },
  mounted() {
    this.initEvents()
    this.eventDate = d3.timeFormat('%Y-%m-%d')(new Date())
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      sendProperties: 'ws/sendProperties',
      getListAssociatedEvents: 'refCircle/associatedEvents/getListAssociatedEvents',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    selectedSuggestion(event, suggestion) {
      this.sendEvent({
        event: event
      })
      this.selectedSection = 0
      this.isAnySuggestionSelected = true
      this.eventLabel = suggestion.defaultLabel
      this.severity = suggestion.severity
      this.selectedEventCode = suggestion.code
      this.resourceType = suggestion.resourceType
      this.$emit('eventSection', { name: suggestion.section, id: 0})
      this.collaborativeEventTreated()
    },
    initEvents() {
      d3.select(this.$refs['severity-input'])
        .on('change', (event) => {
          this.sendEvent({
            event: event,
            params: {
              text: this.severity
            }
          })
          this.collaborativeEventTreated()
        })

      d3.select(this.$refs['date-input'])
        .on('change', (event) => {
          this.sendEvent({
            event: event,
            params: {
              text: this.eventDate
            }
          })
          this.collaborativeEventTreated()
        })

      d3.select(this.$refs['input-diag'])
        .on('input', async (event) => {
          //a partir du moment que l'utilisateur touche à l'input ce n'est plus un event codifié mais custom
          this.resourceType = 'EyeCustom'
          this.isAnySuggestionSelected = false
          this.selectedEventCode = null
          const lenText = this.eventLabel.length

          if (event.isTrusted) {
            if (this.timeoutInterval !== null) {
              clearTimeout(this.timeoutInterval)
            }
            
            if (lenText < 2) {
              this.timeoutInterval = setTimeout(() => {
                this.sendEvent({
                  event: event,
                  params: {
                    text: ''
                  }
                })
              }, 500)
              return
            }

            if (this.eventLabel[lenText - 1] === ' ' && this.eventLabel[lenText - 2] !== ' ') {
              this.sendEvent({
                event: event,
                params: {
                  text: this.eventLabel
                }
              })
              this.makeSuggestionRequest({
                sentence: this.eventLabel
              })
            } else {
              this.timeoutInterval = setTimeout(() => {
                this.sendEvent({
                  event: event,
                  params: {
                    text: this.eventLabel
                  }
                })
                this.makeSuggestionRequest({
                  sentence: this.eventLabel
                })
              }, 500)
            }
          } else if (lenText >= 2) {
            this.sendEvent({ event: event })
            this.makeSuggestionRequest({
              sentence: this.eventLabel
            })
            this.collaborativeEventTreated()
          }
        })

      d3.select(this.$refs['suggestions-modal'])
        .on('scroll', () => {
          this.sendProperties({
            target: this.$refs['suggestions-modal'],
            params: {
              scrollTop:this.$refs['suggestions-modal'].scrollTop
            }
          })
        })
    },
    async onSelectedSectionChange(event, idSection) {
      if (event.isTrusted) {
        this.sendEvent({
          event: event,
          params: {
            text: idSection,
            change: true
          }
        })
      } else {
        this.sendEvent({event: event})
      }
      const selectedSection = this.sections.find(section => section.id === idSection)
      if (selectedSection !== undefined) {
        this.$emit('eventSection', { name: selectedSection.name, id: selectedSection.id })

        if (this.eventLabel === '') {
          const info_concat = this.$store.state.event.common.events
            .filter(x => x.Name == selectedSection.name)
            .map(x => x.defaultLabel).join(" ")
          await this.fetchMotsClefs(info_concat)
        }
      }
      this.collaborativeEventTreated()
    },
    async makeSuggestionRequest({sentence}) {
      try {
        let response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/services/autocomplete?sentence=${sentence}`, {
          credentials: 'include'
        })
        response = await response.json()
        this.suggestions = response
        const info_concat = this.suggestions
          .map(x => x.defaultLabel).join(" ")
        await this.fetchMotsClefs(info_concat)
      } catch (err) {
        console.error(err)
      }
    },
    async onShowAssociatedEvents(event, code) {
      this.sendEvent({event})
      event.preventDefault()
      event.stopPropagation()
      this.suggestions = await this.getListAssociatedEvents(code)
      this.collaborativeEventTreated()
    },
    checkForm() {
      this.errors = {
        severity: [],
        date: [],
        event: [],
        section: []
      }

      if (this.severity < 1 || this.severity > 4) {
        this.errors.severity.push('La sévérité doit-être comprise entre 1 et 4')
      }
      if (this.eventDate === "") {
        this.errors.date.push("Une date est requise")
      }
      if (this.eventLabel.length < 3) {
        this.errors.event.push("Un minimum de 3 caractères est requis")
      }
      if (this.isAnySuggestionSelected === false && this.selectedSection === 0) {
        this.errors.section.push("Une catégorie est requise")
      }
    },
    resetForm() {
      this.selectedEventCode = null
      this.eventLabel = ""
      this.severity = "1"
      // this.eventDate = d3.timeFormat('%Y-%m-%d')(new Date())
      this.suggestions = []
      this.errors = {}
      this.resourceType = 'EyeCustom'
    },
    setCurrentDate(event) {
      this.sendEvent({event: event})
      this.eventDate = d3.timeFormat('%Y-%m-%d')(new Date())
      this.collaborativeEventTreated()
    },
    editEvent(event) {
      const date = event.onsetDateTime || event.date
      this.selectedEventCode = event.code
      this.eventLabel = event.defaultLabel
      this.severity = event.severity
      this.eventDate = d3.timeFormat('%Y-%m-%d')(new Date(date))
      this.suggestions = []
      this.errors = {}
      this.resourceType = event.resourceType

      this.selectedSection = event.resourceType === 'EyeCustom' ? event.idSection : 0
      this.isAnySuggestionSelected = event.resourceType !== 'EyeCustom'
      this.$emit('eventSection', { name: event.Name, id: this.selectedSection})
    },
    getMainInformation() {
      this.checkForm()

      for (const errors of Object.values(this.errors)) {
        if (errors.length > 0) {
          return null
        }
      }

      return {
        selectedEventCode: this.selectedEventCode,
        eventLabel: this.eventLabel,
        severity: this.severity,
        eventDate: this.eventDate,
        resourceType: this.resourceType
      }
    },    
    async fetchMotsClefs(data) {
      try {
        const response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/services/fetch_motsClefs`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({text: data, label:this.eventLabel, n:7})
        })
        const res = await response.json()
        if (res) {
          this.mots_clefs = res.mots_clefs
        }
      } catch (error) {
        console.error('Error making the POST request:', error)
      }
    },
    async updateEventLabel(event, mot) {
      this.sendEvent({ event: event })
      this.eventLabel = this.eventLabel.length > 0 
        ? `${this.eventLabel} ${mot}`
        : mot
      await this.makeSuggestionRequest({
        sentence: this.eventLabel
      })
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
#suggestions-modal ul {
  overflow: scroll;
}

label {
  font-size: 1.1rem;
}

.label-box {
  margin-bottom: 1rem;
}

.date-container p {
  margin-left: 10px;
  font-size: 1.1rem;
}
.motclef-row {
  display: flex;
  gap: 0px;
  margin-bottom: 0px;
}
</style>