<template>
  <div>
    <div class="horizontal-align-container">
      <div id="home-list">
        <EyediagLogo class="eyediag-logo" />
        <div
          v-for="item in menu_item"
          :key="item"
          class="home-patient-description"
          :class="{ 'selected-item': item === menu_item_selected }"
          e2e="homeMenuItem"
        >
          <div
            class="collaborative-patient"
            :style="{ cursor: 'pointer' }"
            @click="(e) => select_menu_item(e, item)"
          >
            <p>{{ item }}</p>
          </div>
        </div>
        <select
          v-if="patientContext.length > 1"
          v-model="selectedPatientContext"
          @change="onChangePatientContext"
        >
          <option
            v-for="(typePatient, index) in patientContext"
            :key="index"
            :value="typePatient.id"
          >
            {{ typePatient.label }}
          </option>
        </select>
      </div>
      <div id="patient-list">
        <SubMenuList
          v-if="subMenuItems.name !== undefined"
          :menu-item-selected="menu_item_selected"
          :folder="subMenuItems"
          @update-patient-id="update_patient_id"
          @on-record="onRecord"
        />
        <div
          v-if="menu_item_selected === 'TestE2e'"
          class="collaborative-patient cursor-pointer"
          @click="onCreateE2e"
        >
          <span>+ Créer un test</span>
        </div>
      </div>
      <div class="custom-div">
        <PatientPreview
          v-if="menu_item_selected === 'Patients'"
          :patient-data="selected_patientData"
        />
        <RecordPreview
          v-if="menu_item_selected === 'Record'"
          :record-data="hoveredRecord"
        />
      </div>
    </div>
    <WeveyesLogo class="weveyes-logo" />
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import PatientPreview from './PatientPreview.vue'
import RecordPreview from './RecordPreview.vue'
import SubMenuList from '@/views/Home/SubMenuList.vue'
import utils from '@/libraries/utils.js'
import * as d3 from 'd3'

import WeveyesLogo from '@/assets/icons/weveyes.vue'
import EyediagLogo from '@/assets/icons/eyediag.vue'
import UserRole from '@/shared/enums/userRole'
import * as hierarchy from '@/shared/enums/hierarchy.js'

import dataSourceComposition from '@/shared/enums/dataSourceComposition.js'

export default {
  name: "HomeEye",
  components: {
    PatientPreview,
    RecordPreview,
    WeveyesLogo,
    EyediagLogo,
    SubMenuList
  },
  data: () => ({
    groupedResult: false,
    /**
     * Liste des patients disponibles dans l'application
     * @type {Object[]}
     */
    patientsLocal: {},
    autresLocal: {
      id: 1,
      name: 'other',
      children: [],
      patients: [
        { id: 9, firstName: "Many Patients", lastName: '', description: '(Composition de patients)', collaborative: true },
        { id: 13, firstName: "Cohorte", lastName: '', collaborative: true }
      ]
      // { id: 999, nom: "Aléatoire", collaborative: false },
    },
    menu_item_selected: 'Patients',
    selected_patientData: null,
    loaded_patientData: [],
    subMenuItems: {},
    hoveredRecord: null,
    selectedPatientContext: null
  }),
  computed: {
    ...mapGetters({
      patientData: 'patient/patientData',
      userData: 'user/userData',
      typePatientContext: 'user/typePatientContext',
      availableTypePatientContext: 'user/availableTypePatientContext'
    }),
    menu_item() {
      const menu = ['Patients']
      if (this.userData.Role.includes(UserRole.UNIVERSEL)) {
        menu.push('Universel')
      }
      if (this.typePatientContext === UserRole.GENERALISTE) {
        menu.push('Plus')
      }
      if (this.userData.Role.includes(UserRole.CREATE_ACCOUNT) || this.userData.Role.includes(UserRole.CREATE_ACCOUNT_WITH_CREDIT)) {
        menu.push('Créer un compte')
      }
      if (this.userData.Role.includes(UserRole.ADMIN)) {
        menu.push('Record')
      }
      if (this.userData.Role.includes(UserRole.ROBOT)) {
        menu.push('TestE2e')
      }

      return menu
    },
    patientContext() {
      const results = []
      const ctxLabel = {[UserRole.GENERALISTE]: 'Generaliste', [UserRole.PHARMACIEN]: 'Pharmacien', [UserRole.QOR]: 'Qor'}

      for (const patientContext of this.availableTypePatientContext) {
        results.push({
          id: patientContext,
          label: ctxLabel[patientContext]
        })
      }

      return results
    }
  },
  async mounted() {
    await this.fetchPatientLocal()
    this.subMenuItems = await this.display_menu_items()
    this.$store.dispatch('resetState')
    this.selectedPatientContext = this.typePatientContext
    this.setHierarchyConfigFromPatientContext()
    this.initScrollEmitter()
    //Rôle de ce collaborativeEventTreated ?
    this.collaborativeEventTreated()
  },
  methods: {
    ...mapActions({
      'sendEvent': 'ws/sendEvent',
      'collaborativeEventTreated': 'ws/collaborativeEventTreated',
      'getPatientData': 'patient/getPatientData',
      'getRecords': 'record/replay/getRecords',
      'getRecord': 'record/replay/getRecord',
      'getPatientsList': 'patient/getPatientsList',
      'startRecording': 'record/record/startRecording'
    }),
    async onRecord(patient) {
      this.menu_item_selected = 'Patients'
      this.subMenuItems = await this.display_menu_items()
      await this.$nextTick()
      await this.getRecord(patient)
      this.$store.commit(`e2eTesting/${mutationTypes.E2E_SET_TEST_NAME}`, patient.TestName)
    },
    async update_patient_id(event, patient, folder) {
      if (['Patients', 'Plus'].includes(this.menu_item_selected)) {
        this.$store.dispatch('ws/sendEvent', { event: event })
        this.$store.commit(`patient/${mutationTypes.SET_FOLDER_ID}`, folder.id)
        this.$store.commit(`patient/${mutationTypes.SET_ID_PATIENT}`, patient.id)
        if (this.menu_item_selected === 'Patients') {
          const res = this.loaded_patientData.find(obj => obj.id == patient.id)
          if (res) {
            this.selected_patientData = res
          } else {
            await this.getPatientData()
            this.loaded_patientData.push(this.patientData) 
            this.selected_patientData = this.patientData
          }
        }
        this.$store.dispatch('ws/collaborativeEventTreated')
      }
    },
    async select_menu_item(event, menu_item) {
      await this.sendEvent({event})
      this.menu_item_selected = menu_item

      if (this.menu_item_selected == 'Universel') {
        const mode = {
          type: eyediagMode.mode.UNIVERSAL,
          options: {},
        };
        this.$store.commit(mutationTypes.SET_EYEDIAG_MODE, mode);
        await this.$router.push({ name: "ImportView" })
      } else if (this.menu_item_selected === 'Créer un compte') {
        await this.$router.push({ name: 'AccountCreation' })
      } else {
        this.subMenuItems = await this.display_menu_items()
      }

      this.collaborativeEventTreated()
    },

    async display_menu_items() {
      const baseTemplateFolder = {
        id: 1,
        name: 'other',
        children: [],
        patients: []
      }

      switch (this.menu_item_selected) {
      case 'Patients':
        return this.patientsLocal
      case 'Plus':
        return this.autresLocal
      case 'Record':
        baseTemplateFolder.patients = await this.getRecords()
        return baseTemplateFolder
      case 'TestE2e':
        baseTemplateFolder.patients = await this.getE2eTests()
        return baseTemplateFolder
      }
    },

    getE2eTests() {
      return []
    },

    onImport() {
      this.$router.push({ name: "Test" })
    },

    async fetchPatientLocal() {
      const patientsListData = await this.getPatientsList()
      this.patientsLocal = patientsListData.patients
    },
    async onCreateE2e() {
      const testName = window.prompt("Nom du test")
      this.$store.commit(`e2eTesting/${mutationTypes.E2E_SET_TEST_NAME}`, testName)
      this.menu_item_selected = 'Patients'
      this.subMenuItems = await this.display_menu_items()
      await this.$nextTick()
      this.$store.commit(`record/record/${mutationTypes.SET_RECORD_STATUS}`, true)
      this.startRecording()
    },
    async onChangePatientContext(event) {
      this.sendEvent({
        event: event,
        params: {
          text: this.selectedPatientContext,
          change: true
        }
      })
      this.patientsLocal = {}
      this.subMenuItems = {}
      this.$store.commit(`user/${mutationTypes.SET_TYPE_PATIENT_CONTEXT}`, this.selectedPatientContext)
      await this.fetchPatientLocal()
      this.setHierarchyConfigFromPatientContext()
      this.subMenuItems = await this.display_menu_items()
      this.collaborativeEventTreated()
    },
    setHierarchyConfigFromPatientContext() {
      const lastHierarchy = hierarchy.rootHierarchyFromPatientContext[this.selectedPatientContext] || hierarchy.rootHierarchyFromPatientContext[UserRole.GENERALISTE]
      this.$store.commit(mutationTypes.SET_HISTORY_SECTIONS, [{
        asSection: null, sections: [], hierarchy: lastHierarchy
      }])
      this.$store.commit(mutationTypes.SET_INDEX_HISTORY_SECTIONS, 0)
      this.$store.commit(mutationTypes.SET_HIERARCHY, lastHierarchy)
    },
    async onPatientGroupCohorte(event, group) {
      this.sendEvent({event: event})
      this.$store.commit(`patient/${mutationTypes.UPDATE_DATA_SOURCE_COMPOSITION}`, dataSourceComposition.COHORT)
      this.$store.commit(`patient/${mutationTypes.SET_ID_PATIENT}`, group)
      this.$store.commit(`patient/${mutationTypes.SET_FOLDER_ID}`)
      await this.$router.push({ name: "EyeApp" })
      this.collaborativeEventTreated()
    },
    /**
     * Permet de contrôler la position de la souris à l'intérieur du conteneur de la liste des patients puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    initScrollEmitter() {
      d3.select('#patient-list')
        .on('mousemove', (event) => {
          utils.sendCheckScroll(event, this.$store)
        })
    }
  },
};
</script>

<style>
#patient-list {
  overflow: scroll;
  height: 100vh;
}

.home-patient-description {
  display: flex;
  color: var(--color-text);
  margin-bottom: 10px;
}

.selected-item {
  border: 2px solid var(--color-text);
}

.custom-div {
  width: 400px; 
  padding: 10px;
}

.horizontal-align-container {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
  align-items: top; /* Adjust as needed */
  font-size: 1.6rem;
}

#home-list {
  height: 90vh; /* Set a fixed height for the scrollable container */
  margin-top: 30px; /* Add a top margin of 30px */
  margin-left: 5%; /* Leave one-fifth of the page empty as a margin on the left */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 300px;
}

.collaborative-patient {
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.6s;
  padding: 10px;
  width: 300px;
  font-size: 1.4rem
}
.collaborative-patient > * {
  font-size: 1.4rem
}

.non-collaborative-patient {
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.6s;
  padding: 10px;
  width: 300px;
  background-color: lightgray;
}

.collaborative-patient:hover {
  border: 2px solid var(--color-text);
}

.weveyes-logo {
  position: absolute;
  left: 5%;
  bottom: 5%;
  transform: scale(0.3);
  transform-origin: bottom left;
}

.eyediag-logo {
  transform-origin: top left;
  transform: scale(0.8);
}
</style>
