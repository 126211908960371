<template>
  <ListBase
    id="list-events-to-add" 
    ref="list-base"
  >
    <h3 class="m-0">
      Ajouts récents
    </h3>
    <div class="list-events-to-add-content">
      <ul
        v-if="lastAddEvents.length > 0"
        class="list-none p-0"
      >
        <li
          v-for="(event, index) in lastAddEvents"
          :key="index"
          class="flex flex-row pb-5 justify-between"
        >
          <div
            @click="(e) => onClickEvent(e, event)"
          >
            <p>
              {{ `${(event.code) ? '['+event.code+'] ' : ''}${event.defaultLabel}` }}
            </p>
            <p>{{ event.onsetDateTime?.substring(0, 10) || "Non daté" }}</p>
          </div>
        </li>
      </ul>
      <div v-else>
        <p
          class="flex flex-row justify-center"
        >
          Aucun événement récemment enregistré
        </p>
      </div>
    </div>
  </ListBase>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'
import ListBase from '@/components/borderLists/ListBase.vue'

export default {
  components: {
    ListBase
  },
  computed: {
    ...mapGetters({
      unitPerCircle: "circle/unitPerCircle",
      periodUnit: "circle/periodUnit",
      allEvents: "event/common/allEvents"
    }),
    lastAddEvents() {
      let sortedList = [...this.allEvents]
      sortedList = sortedList.sort((a, b) => b.id - a.id)
      sortedList = sortedList.slice(0, 10)

      return sortedList
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      getDataRepresentation: 'circle/getDataRepresentation',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * Permet la gestion de l'ouverture / fermeture de la liste
     * @param  {...any} params Paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    onClickEvent(event, eventToEdit) {
      this.sendEvent({event})
      this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_EVENT}`, eventToEdit)
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
.list-events-to-add-content {
  margin-inline: 5px;
}

h3 {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

li * {
  cursor: pointer;
}
</style>